.md {
  @media print {

    *,
    *:before,
    *:after {
      background: transparent !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    abbr[title]:after {
      content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  pre,
  code {
    font-family: Menlo, Monaco, "Courier New", monospace;
  }

  pre {
    padding: .5rem;
    line-height: 1.25;
    overflow-x: scroll;
  }

  a:hover,
  a:focus,
  a:active {
    color: #2980b9;
  }

  .modest-no-decoration {
    text-decoration: none;
  }

  font-size: 12px;

  @media screen and (min-width: 32rem) and (max-width: 48rem) {
    font-size: 15px;
  }

  @media screen and (min-width: 48rem) {
    font-size: 16px;
  }

  line-height: 1.85;

  p,
  .modest-p {
    font-size: 1rem;
    // margin-bottom: 1.3rem;
    line-height: 1.5rem;
  }

  h1,
  .modest-h1,
  h2,
  .modest-h2,
  h3,
  .modest-h3,
  h4,
  .modest-h4 {
    margin: 1.414rem 0 .5rem;
    font-weight: inherit;
    line-height: 1.42;
  }

  h1,
  .modest-h1 {
    margin-top: 0;
    font-size: 2.998rem;
  }

  h2,
  .modest-h2 {
    font-size: 1.827rem;
  }

  h3,
  .modest-h3 {
    font-size: 1.5999rem;
  }

  h4,
  .modest-h4 {
    font-size: 1.214rem;
  }

  h5,
  .modest-h5 {
    font-size: 1.121rem;
  }

  h6,
  .modest-h6 {
    font-size: .88rem;
  }

  small,
  .modest-small {
    font-size: .707em;
  }

  /* https://github.com/mrmrs/fluidity */

  img,
  canvas,
  iframe,
  video,
  svg,
  select,
  textarea {
    max-width: 100%;
  }

  h1 {
    line-height: 3.5rem;

    @media (max-width: 768px) {
      line-height: 2.8rem;
    }
  }

  h2,
  h3 
  h4,
  h5,
  h6 {
    line-height: 2.3rem;
    @media (max-width: 768px) {
      line-height: 2rem;
    }
  }


  // h1,
  // h2,
  // h3 {
  //   // border-bottom: 2px solid #fafafa;
  //   // margin-bottom: 1.15rem;
  //   // padding-bottom: .5rem;
  // }

  blockquote {
    border-left: 8px solid #fafafa;
    padding: 1rem;
  }

  pre,
  code {
    background-color: #fafafa;
  }

  // .global:md-img img {
  //   @apply w-32 md:w-48 lg:w-48 xl:w-60;
  // }


  // .md-img img {
  //   width: 2.5rem; // equivalent to w-10 in Tailwind
  // }

  // @media (min-width: 768px) { // md breakpoint
  //   .md-img img {
  //     width: 3rem; // equivalent to md:w-12 in Tailwind
  //   }
  // }

  // @media (min-width: 1024px) { // lg breakpoint
  //   .md-img img {
  //     width: 3.5rem; // equivalent to lg:w-14 in Tailwind
  //   }
  // }

  // @media (min-width: 1280px) { // xl breakpoint
  //   .md-img img {
  //     width: 4rem; // equivalent to xl:w-16 in Tailwind
  //   }
  // }
}

// @source: https://github.com/markdowncss/modest/blob/master/css/modest.css